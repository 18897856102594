import React, { useEffect } from 'react';
import { ChakraProvider, Box, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';

// Define the animation keyframes
const moveShapes = keyframes`
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, 50px); }
  100% { transform: translate(0, 0); }
`;

// Define a component for animated square shapes
const AnimatedShape = ({ size, color, top, left }) => (
  <Box
    width={size}
    height={size}
    backgroundColor={color}
    position="absolute"
    top={top}
    left={left}
    borderRadius="0" // Square shapes
    animation={`${moveShapes} 5s infinite ease-in-out`}
  />
);

const MotionBox = motion(Box);

function App() {
  useEffect(() => {
    // Create and append the Twitter embed script
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ChakraProvider>
      <MotionBox
        w="100vw"
        h="100vh"
        bgGradient="linear(to-l, #0047AB, #1E90FF)" // Cobalt blue to Dodger blue gradient
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        overflow="hidden"
      >
        {/* Animated square shapes */}
        <AnimatedShape size="50px" color="rgba(255, 255, 255, 0.3)" top="10%" left="10%" />
        {/* Removed 70px square */}
        <AnimatedShape size="100px" color="rgba(255, 255, 255, 0.3)" top="60%" left="20%" />
        <AnimatedShape size="40px" color="rgba(255, 255, 255, 0.3)" top="80%" left="70%" />
        <AnimatedShape size="60px" color="rgba(255, 255, 255, 0.3)" top="50%" left="80%" />

        {/* Twitter Embed */}
        <Box
          as="blockquote"
          className="twitter-tweet"
          data-media-max-width="560"
          style={{
            maxWidth: '560px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <p lang="tl" dir="ltr">
            Big Sister General Kamala Harris <a href="https://t.co/c2ub5kpZQ4">pic.twitter.com/c2ub5kpZQ4</a>
          </p>
          &mdash; uncle gworl (@_uncle_gworl) <a href="https://twitter.com/_uncle_gworl/status/1592009737796714496?ref_src=twsrc%5Etfw">November 14, 2022</a>
        </Box>
      </MotionBox>
    </ChakraProvider>
  );
}

export default App;
